import { useEffect, useState } from 'react'
import { fetchWithAuthorizationHeaderAsJson, fetchWithAuthorizationHeaderAsRaw } from '../client/fetchClient'
import { useIdentity, useUserContext } from '@vp/ubik-context'

const ACCOUNT_ID = 'ozoDdrmewShEcbUDWX8J3V'
const PROFILE_UPDATE_ENDPOINT = `https://profile.cimpress.io/v1/${ACCOUNT_ID}/profile/me`
export const PROFILE_EMAIL_VERIFICATION_ENDPOINT = `https://profile.cimpress.io/v1/${ACCOUNT_ID}/profile/me/verifyEmail`
export const ACCOUNT_DELETION_REQUEST_ENDPOINT_PROD = 'https://backend-delete-account.my-account.vpsvc.com/v1/request-account-deletion'
export const ACCOUNT_DELETION_REQUEST_ENDPOINT_DEV = 'https://backend-delete-account.my-account-dev.vpsvc.com/v1/request-account-deletion'

type SocialIdentity = 'google' | 'facebook'

type ProfileType = {
  email: string;
  firstName: string;
  lastName: string;
  identityProviders: Array<SocialIdentity>;
  canManagePassword: boolean;
  canManageProfile: boolean;
  isEmailVerified: boolean | undefined;
  readOnlyMode: boolean;
  lastPasswordChangedOn: string;
  language?: string;
  requestReceivedForDeletion?: boolean;
}

export const BLANK_PROFILE: ProfileType = {
  email: '',
  firstName: '',
  lastName: '',
  identityProviders: [],
  canManagePassword: false,
  canManageProfile: false, // to allow editing name fields
  isEmailVerified: undefined, // to allow sending email verification mails
  readOnlyMode: true, // to disable edit on any fields when disconnecting primary social identity
  lastPasswordChangedOn: '',
}

export type UseProfileType = {
  isReady: boolean;
  profile: ProfileType;
  profileError: string;
  updateProfile: (
    updatedProfile: Partial<ProfileType>
  ) => Promise<{ serviceError: any | null; emailAlreadyPresent: boolean }>;
  sendVerificationEmail: () => Promise<boolean>;
  sendAccountDeletionRequest: () => Promise<{ success: boolean; error?: Error }>;
}

function useProfile (): UseProfileType {
  const userContext = useUserContext()
  const { identity } = useIdentity()
  const [profile, setProfile] = useState<ProfileType>(BLANK_PROFILE)
  const [profileError, setProfileError] = useState('')

  const authorizationHeader = identity?.authorizationHeader ?? ''

  const updateProfileAddingCustomProperties = (newProfile: ProfileType) => {
    setProfile({
      ...newProfile,
      canManageProfile: newProfile.canManagePassword,
    })
  }

  const forceReload = async () => {
    if (!identity?.isSignedIn) return

    const result: { profile: ProfileType; profileError: string } = {
      profile: BLANK_PROFILE,
      profileError: '',
    }
    try {
      result.profile = await fetchWithAuthorizationHeaderAsJson(PROFILE_UPDATE_ENDPOINT + `?q=${Date.now()}`, authorizationHeader) as ProfileType
    } catch {
      result.profileError = 'Error getting profile'
    }

    updateProfileAddingCustomProperties(result.profile)
    setProfileError(result.profileError)
  }

  useEffect(() => {
    forceReload()
  }, [identity])

  const sendVerificationEmail = async () => {
    try {
      await fetchWithAuthorizationHeaderAsJson(PROFILE_EMAIL_VERIFICATION_ENDPOINT, authorizationHeader, {
        method: 'POST',
        body: JSON.stringify({
          language: userContext.locale,
        })
      })
      return true
    } catch {
      return false
    }
  }

  const sendAccountDeletionRequest = async () => {
    try {
      const accountDeletionEndpoint =
        userContext.environment === 'staging' ? ACCOUNT_DELETION_REQUEST_ENDPOINT_DEV : ACCOUNT_DELETION_REQUEST_ENDPOINT_PROD
      const response = await fetchWithAuthorizationHeaderAsRaw(accountDeletionEndpoint, authorizationHeader, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        }
      })
      if (!response.ok) {
        throw new Error('Failed to request account deletion. Status: ' + response.status)
      }
      return { success: true }
    } catch (error: any) {
      if (error.message.endsWith('422')) {
        // 422, in this case, means the deletion has already been requested, no action was done, so we treat it as a success
        return { success: true }
      }
      return { success: false, error: error as Error }
    }
  }

  const isEmailAlreadyPresent = (profilePropsToUpdate: Partial<ProfileType>, err: any) =>
    profilePropsToUpdate.email &&
    err?.response?.status === 400 &&
    err.response.data?.detail?.includes('Auth0 error for user')

  const updateProfile = async (profilePropsToUpdate: Partial<ProfileType>) => {
    const result = { serviceError: null, emailAlreadyPresent: false }
    try {
      const newProfile = await fetchWithAuthorizationHeaderAsJson(PROFILE_UPDATE_ENDPOINT, authorizationHeader, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(profilePropsToUpdate),
      }) as ProfileType

      updateProfileAddingCustomProperties(newProfile)
    } catch (err: any) {
      result.emailAlreadyPresent = isEmailAlreadyPresent(profilePropsToUpdate, err)
      if (!result.emailAlreadyPresent) { result.serviceError = `An error happened while updating the profile: ${err.message}` as any }
    }

    return result
  }

  const checkIfReady = () => !profileError && (!!profile.email || !!profile.firstName)

  return {
    isReady: checkIfReady(),
    profile,
    profileError,
    updateProfile,
    sendVerificationEmail,
    sendAccountDeletionRequest,
  }
}

export default useProfile
export type { ProfileType, SocialIdentity }
